import { Styles } from "../style/Styles";
import { THEME } from "../style/Themes";
import { LinkText } from "./common/Text";

export default function Footer() {
  return (
    <footer
      style={{
        backgroundColor: THEME.palette.secondary.contrastText,
        bottom: 0,
        width: "100%",
        marginTop: "auto",
        display: "flex",
        alignItems: "center",
        fontSize: "80%",
      }}
    >
      <div
        className="bottom-row"
        style={{
          display: "flex",
        }}
      >
        <LinkText variant="inherit" sx={Styles.footer}>
          © 2025 D.E.T. Eatery
        </LinkText>
        <LinkText href="/legal/terms-of-use.html" sx={Styles.footer}>
          Terms of Use
        </LinkText>
        <LinkText href="/legal/privacy.html" sx={Styles.footer}>
          Privacy Policy
        </LinkText>
        <LinkText href="/legal/report-issue" sx={Styles.footer}>
          Report an issue
        </LinkText>
        <LinkText variant="inherit" sx={Styles.footer}>
          Version 1.1.2
        </LinkText>
      </div>
    </footer>
  );
}
